import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { generateParamsForRoutes } from 'src/app/helper/utils';
import { Medium } from 'src/app/models/medium';
import { BaseResponse } from 'src/app/models/base-response';
import { Observable } from 'rxjs/internal/Observable';
import { LatestPosition } from 'src/app/models/latest-position';

@Injectable({
  providedIn: 'root'
})
export class MediumService extends ApiService {

  constructor(httpService: HttpService) {
    super(httpService);
    this.baseUrl = EndpointsConstants.medium.base;
    this.logEntity = EndpointsConstants.medium.base;
  }

  unlock(medium: object, id: number): Observable<BaseResponse<Medium>> {
    const url = `${this.baseUrl}${EndpointsConstants.medium.unlock}/${id}`;
    return this.httpService.post(url, medium, true);
  }

  getTrips(page: number = 0, pageSize: number = 0, filters: any = []): Observable<BaseResponse<Medium>> {
    const url = this.baseUrl + EndpointsConstants.medium.trips;
    const params = generateParamsForRoutes(filters, page, pageSize);
    return this.httpService.get(url, params, true);
  }

  getPositionsReport(id: number, page: number = 0, pageSize: number = 0, filters: any = []): Observable<BaseResponse<LatestPosition>> {
    const url = `${this.baseUrl}${EndpointsConstants.medium.position}/${id}`;
    const params = generateParamsForRoutes(filters, page, pageSize);
    return this.httpService.get(url, params, true);
  }

  getStatuses(page: number = 0, pageSize: number = 0, filters: any = []): Observable<BaseResponse<Medium>> {
    const url = this.baseUrl + EndpointsConstants.medium.statuses;
    const params = generateParamsForRoutes(filters, page, pageSize);
    return this.httpService.get(url, params, true);
  }

  exportcsv(filters: any = []) {
    const url = `${this.baseUrl}${EndpointsConstants.file.exportcsv}`;
    const params = generateParamsForRoutes(filters);
    return this.httpService.get(url, params, true);
  }
}
