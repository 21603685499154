import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { BaseResponse } from 'src/app/models/base-response';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private baseUrl: string;

  constructor(private httpService: HttpService) {
    this.baseUrl = EndpointsConstants.profile.base;
  }

  get(endpointProfile: string): Observable<BaseResponse<any>> {
    const url = `${this.baseUrl}${endpointProfile}`;
    return this.httpService.get(url, undefined, true);
  }
}
