import { User } from './user';
import { Subscription } from './subscription';
import { LatestStatus } from './latest-status';
import { BaseEntity } from './base-entity';
import { isNullOrEmptyOrUndefined } from '../helper/utils';
import { CatalogAuthorizationStatus, CatalogAuthorizationDetail } from 'projects/admin/src/app/helpers/catalog';
import { Log } from './log';

export class Payment extends BaseEntity<Payment> {
    id: number;
    idPayment: string;
    paid: boolean;
    paidLabel: string;
    amount: number;
    idUser: number;
    user?: User;
    idSubscription: number;
    subscription?: Subscription;
    idLatestStatus: number;
    latestStatus: LatestStatus;
    idAuthorization: string;
    authorizationAmount: number;
    authorizationStatus: string;
    authorizationDetail: string;
    hideActions: string[];

    constructor(payment?) {
        super(payment);
    }

    parse(e: any): Payment {
        const payment = new Payment(e);
        return payment;
    }

    populate(payment) {
        this.id = payment.id;
        this.paid = payment.paid;
        this.paidLabel = (payment.paid) ? 'Si' : 'No';
        this.amount = payment.amount;
        this.idPayment = payment.idPayment;
        this.idUser = payment.idUser;
        if (payment.user) { this.user = new User(payment.user); }
        this.idSubscription = payment.idSubscription;
        if (payment.subscription) { this.subscription = new Subscription(payment.subscription); }
        this.idLatestStatus = payment.idLatestStatus;
        if (payment.latestStatus) { this.latestStatus = new LatestStatus(payment.latestStatus); }
        if (payment.idAuthorization) { this.idAuthorization = payment.idAuthorization; }
        this.authorizationAmount = payment.authorizationAmount;
        if (payment.authorizationStatus) { this.authorizationStatus = payment.authorizationStatus; }
        if (payment.authorizationDetail) { this.authorizationDetail = payment.authorizationDetail; }
        this.hideActions = this.setHideActions();
    }

    setHideActions?() {
        const items = [];
        // tslint:disable-next-line:max-line-length
        if (isNullOrEmptyOrUndefined(this.idAuthorization) || this.authorizationStatus !== CatalogAuthorizationStatus.authorized || this.authorizationDetail !== CatalogAuthorizationDetail.pendingCapture) {
            items.push('charge');
            items.push('reject');
        }
        return items;
    }
}
