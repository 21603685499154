import { Injectable } from '@angular/core';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { BaseResponse } from 'src/app/models/base-response';
import { BaseEntity } from 'src/app/models/base-entity';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class GeoService extends ApiService {

  constructor(httpService: HttpService) {
    super(httpService);
    this.baseUrl = EndpointsConstants.geo.base;
  }

  getAdminNearBy<T extends BaseEntity<T>>(params): Observable<BaseResponse<T>> {
    const url = this.baseUrl + EndpointsConstants.geo.adminnearby;
    return this.httpService.post(url, params, false);
  }
}
