import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { EndpointsConstants } from '../../../helpers/endpoints';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService extends ApiService {

  constructor(httpService: HttpService) {
    super(httpService);
    this.baseUrl = EndpointsConstants.subscription.base;
    this.logEntity = EndpointsConstants.subscription.base;
  }
}
