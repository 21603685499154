import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { BaseResponse } from 'src/app/models/base-response';
import { BaseEntity } from 'src/app/models/base-entity';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  protected baseUrl = EndpointsConstants.dashboard.base;

  constructor(private httpService: HttpService) { }

  get<T extends BaseEntity<T>>(endpoint: string): Observable<BaseResponse<T>> {
    const url = this.baseUrl + endpoint;
    return this.httpService.get(url);
  }
}
