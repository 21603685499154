import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { EndpointsConstants } from '../../../helpers/endpoints';

@Injectable({
  providedIn: 'root'
})
export class HubService extends ApiService {

  constructor(httpService: HttpService) {
    super(httpService);
    this.baseUrl = EndpointsConstants.hub.base;
    this.logEntity = EndpointsConstants.hub.base;
  }

  ring(id: number, params) {
    const url = `${this.baseUrl}${EndpointsConstants.hub.alarm}/${id}`;
    return this.httpService.post(url, params);
  }
}
