import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { BaseResponse } from 'src/app/models/base-response';
import { Trip } from 'src/app/models/trip';
import { environment } from 'projects/admin/src/environments/environment';
import { generateParamsForRoutes } from 'src/app/helper/utils';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class TripService extends ApiService {

  constructor(
    httpService: HttpService,
    private http: HttpClient
  ) {
    super(httpService);
    this.baseUrl = EndpointsConstants.trip.base;
  }

  adminFinish(idTrip: number): Observable<BaseResponse<Trip>> {
    const url = environment.api.baseUrl + this.baseUrl + EndpointsConstants.trip.adminFinish + '/' + idTrip;
    return this.http.post<BaseResponse<Trip>>(url, {});
  }

  getHeatmap(filters: any = []): Observable<BaseResponse<Trip>> {
    const url = this.baseUrl + EndpointsConstants.trip.heatmap;
    const params = generateParamsForRoutes(filters);
    return this.httpService.get<Trip>(url, params, true);
  }
}
