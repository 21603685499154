import { BaseEntity } from './base-entity';
import { Log } from './log';

export class FaqCategory extends BaseEntity<FaqCategory> {
  id?: number;
  name: string;

  constructor(faqCategory?) {
    super(faqCategory);
  }

  parse(e: any): FaqCategory {
      const faqCategory = new FaqCategory(e);
      return faqCategory;
  }

  populate(faqCategory) {
    if (faqCategory.id) { this.id = faqCategory.id; }
    this.name = faqCategory.name;
  }
}
